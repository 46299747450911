import React, { useEffect, useRef, useState } from 'react';
import { type AnimationProps, motion } from 'framer-motion';

interface AnimateHeightChange {
  children: React.ReactNode;
  className?: string;
  transition?: AnimationProps['transition'];
  disable?: boolean;
}

const defaultTransition = {
  bounce: 0,
  duration: 0.15,
  ease: 'easeOut',
  damping: 20,
  stiffness: 150,
  mass: 0.4,
};

export const AnimateHeightChange: React.FC<AnimateHeightChange> = ({
  children,
  className,
  transition = defaultTransition,
  disable = false,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        const observedHeight = entries[0].contentRect.height;
        setHeight(disable ? 'auto' : observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [disable]);

  return (
    <motion.div
      className={className}
      style={{ height }}
      animate={{ height }}
      transition={transition}
    >
      <div ref={containerRef}>{children}</div>
    </motion.div>
  );
};
