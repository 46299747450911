import React, { useEffect } from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { type ChainflipToken, type Token } from '@/shared/assets/tokens';
import { Callout, ChainTokenLogo, CopyButton } from '@/shared/components';
import useTracking from '@/shared/hooks/useTracking';
import { TokenAmount } from '@/shared/utils';
import { ReceivingStatus } from './ReceivingStatus';
import { RouteDottedConnect } from './RouteDottedConnect';
import { SwappingStatus } from './SwappingStatus';
import useViewTransitionStore, {
  type DepositViewState,
} from '../../../hooks/useViewTransitionStore';
import { type StatusResponse } from '../../../integrations';
import { SwapEvents, type SwapTrackEvents } from '../../../types/track';
import { defaultAnimationProps } from '../../../utils/consts';
import {
  getRefundEgress,
  getSwapEgress,
  getSwapFees,
  getSwapId,
  isSwappingFinished,
} from '../../../utils/sdk';
import { UsdAmount } from '../../UsdAmount';
import { ErrorFrame } from '../frames/ErrorFrame';
import { RefundingFrame } from '../frames/RefundingFrame';
import { SendingFrame } from '../frames/SendingFrame';
import { SuccessFrame } from '../frames/SuccessFrame';

const StartSwapTokenCard = ({
  token,
  amount,
  side,
}: {
  token: ChainflipToken | Token;
  amount: TokenAmount;
  side: 'deposit' | 'receive';
}) => (
  <div
    className={classNames(
      'flex w-full flex-col items-center justify-center gap-y-1.5 rounded-md border border-cf-gray-5 p-2',
      'shadow-[0px_0px_0px_2px_#141414,0px_0px_14px_0px_rgba(0,0,0,0.32),3px_3px_18px_0px_rgba(0,0,0,0.16)_inset]',
      '[background:linear-gradient(180deg,rgba(255,255,255,0.06)_-19.26%,rgba(255,255,255,0)_60.89%),#252525]',
    )}
  >
    <span className="font-aeonikBold text-12 text-cf-light-2">
      {side === 'deposit' ? 'Deposit' : 'Receive'}
    </span>
    <ChainTokenLogo token={token} />
    <div className="flex items-center justify-between gap-x-2 font-aeonikMedium text-14 text-white">
      {amount.toCapped()} {token.symbol}
      {side === 'deposit' && <CopyButton textToCopy={amount.toFixed()} />}
    </div>
    <span className="text-13 text-cf-light-2">
      <UsdAmount token={token} tokenAmount={amount} />
    </span>
  </div>
);

const StartSwapStatus = ({ status }: { status: StatusResponse }) => (
  <div className="flex flex-row" key="start-swap-status">
    <StartSwapTokenCard
      key="deposit"
      side="deposit"
      token={status.route.srcToken}
      amount={status.route.srcAmount}
    />
    <div className="z-10 mx-[-17px] self-center">
      <RouteDottedConnect />
    </div>
    <StartSwapTokenCard
      key="receive"
      side="receive"
      token={status.route.destToken}
      amount={status.route.destAmount}
    />
  </div>
);

export const BroadcastStatus = ({ status }: { status: StatusResponse }) => {
  const track = useTracking<SwapTrackEvents>();
  const fees = getSwapFees(status);
  const swapEgress = getSwapEgress(status);
  const refundEgress = getRefundEgress(status);
  const refundAmount =
    refundEgress && new TokenAmount(refundEgress.amount, status.route.srcToken.decimals);

  useEffect(() => {
    const eventProps = {
      assetFrom: `${status.route.srcToken.chain.name}.${status.route.srcToken.symbol}`,
      assetFromAmount: status.route.srcAmount.toFixed(),
      assetTo: `${status.route.destToken.chain.name}.${status.route.destToken.symbol}`,
      assetToAmount: status.route.destAmount.toFixed(),
      rate: status.route.destAmount.ratio(status.route.srcAmount).toFixed(),
      destinationAddress: status.destAddress,
      integration: status.integration,
    };
    if (status.status !== 'completed') {
      track(SwapEvents.SendingFunds, {
        props: { ...eventProps },
      });
    } else {
      track(SwapEvents.SwapComplete, {
        props: {
          ...eventProps,
          boostFee:
            status.integration === 'chainflip'
              ? fees?.find((fee) => fee.type === 'BOOST')?.amount
              : undefined,
        },
      });
    }
  }, [status.status]);

  let Frame = SendingFrame;
  let testId;
  let text;
  let callout;

  if (refundEgress && swapEgress) {
    text = 'Partial refund initiated';
    testId = 'partial-refund-initiated-card';
    callout = (
      <span className="text-14">
        Chainflip couldn&apos;t meet your minimum payout rate. Your remaining{' '}
        <span className="font-aeonikBold">
          {refundAmount?.toCapped()} {status.route.srcToken.symbol}
        </span>{' '}
        deposit will be refunded
      </span>
    );
  } else if (refundEgress) {
    Frame = RefundingFrame;
    text = 'Refund initiated';
    testId = 'refund-initiated-card';
    callout = (
      <span className="text-14">
        Chainflip couldn&apos;t meet your minimum payout rate. Your{' '}
        <span className="font-aeonikBold">
          {refundAmount?.toCapped()} {status.route.srcToken.symbol}
        </span>{' '}
        deposit will be refunded
      </span>
    );
  } else {
    text = 'Sending funds';
    testId = 'broadcast-requested-card';
  }

  if (status.status === 'completed') {
    Frame = SuccessFrame;
    text = refundEgress ? 'Refund complete' : 'Swap complete';
    testId = refundEgress ? 'refund-completed-card' : 'swap-completed-card';
    if (refundEgress && swapEgress) {
      text = 'Partial refund complete';
      testId = 'partial-refund-completed-card';
    }
  }
  return (
    <div className="flex flex-col items-center p-3 pt-0 font-aeonikMedium">
      <div className="flex h-[110px] justify-center">
        <Frame className="pointer-events-none absolute" />
      </div>
      <div className="text-24 text-cf-white">
        <span data-testid={testId}>{text}</span>
      </div>
      {refundAmount?.gt(0) && (
        <div className="text-16">
          <div className="inline-flex flex-row items-center gap-1 text-cf-light-3">
            <div>{refundAmount.toCapped()}</div>
            <ChainTokenLogo token={status.route.srcToken} size="xsmall" displayChainLogo={false} />
            <div>{status.route.srcToken.symbol}</div>
          </div>
          <span className="text-cf-light-2">
            {' '}
            (<UsdAmount token={status.route.srcToken} tokenAmount={refundAmount} />)
          </span>
        </div>
      )}
      {status.route.destAmount.gt(0) && (
        <div className="text-16">
          <div className="inline-flex flex-row items-center gap-1 text-cf-light-3">
            <div>{status.route.destAmount.toCapped()}</div>
            <ChainTokenLogo token={status.route.destToken} size="xsmall" displayChainLogo={false} />
            <div>{status.route.destToken.symbol}</div>
          </div>
          <span className="text-cf-light-2">
            {' '}
            (<UsdAmount token={status.route.destToken} tokenAmount={status.route.destAmount} />)
          </span>
        </div>
      )}
      {callout && status.status === 'waiting_for_dest_tx' && (
        <div className="mt-[16px]">
          <Callout type="warning">{callout}</Callout>
        </div>
      )}
    </div>
  );
};

const SwapProcessingStatus = ({
  status,
  depositViewState,
}: {
  status: StatusResponse;
  depositViewState: DepositViewState;
}) => {
  if (status.status === 'waiting_for_src_tx_confirmation') {
    return (
      <motion.div key="receiving-status" {...defaultAnimationProps}>
        <ReceivingStatus status={status} />
      </motion.div>
    );
  }
  /* eslint-disable no-nested-ternary */
  return (
    <AnimatePresence mode="wait">
      {status.status === 'waiting_for_dest_tx' && (
        <motion.div key="waiting-for-destination-status" {...defaultAnimationProps}>
          <AnimatePresence mode="wait">
            {depositViewState !== 'transition-finished' ? (
              <motion.div key="receiving-status" {...defaultAnimationProps}>
                <ReceivingStatus status={status} />
              </motion.div>
            ) : !isSwappingFinished(status) ? (
              <motion.div key="swapping-status" {...defaultAnimationProps}>
                <SwappingStatus status={status} />
              </motion.div>
            ) : (
              <motion.div key="broadcast-status" {...defaultAnimationProps}>
                <BroadcastStatus status={status} />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      )}
      {status.status === 'completed' && (
        <motion.div key="broadcast-status" {...defaultAnimationProps}>
          <BroadcastStatus status={status} />
        </motion.div>
      )}
    </AnimatePresence>
  );
  /* eslint-enable no-nested-ternary */
};

const ErrorStatus = ({ status }: { status: StatusResponse }) => (
  <div className="flex flex-col items-center p-3 pt-0 font-aeonikMedium">
    <div className="flex h-[110px] justify-center">
      <ErrorFrame className="pointer-events-none absolute" />
    </div>
    <div className="text-24 text-cf-white">Swap failed</div>
    <div className="text-center text-16">
      Contact us with ticket <span className="text-cf-white">{getSwapId(status)}</span> to resolve
      the issue.
    </div>
  </div>
);

export const StatusSection = ({ status }: { status: StatusResponse }) => {
  const track = useTracking<SwapTrackEvents>();
  // reset transition on initial load and let child components decide on the current step
  const { depositViewState, setDepositViewState } = useViewTransitionStore();
  useEffect(() => {
    if (['waiting_for_src_tx', 'waiting_for_src_tx_confirmation'].includes(status.status))
      setDepositViewState('awaiting-deposit-tx');
    else {
      setDepositViewState('transition-finished');
    }
  }, []);

  const swapFinished = isSwappingFinished(status);
  useEffect(() => {
    if (swapFinished) {
      track(SwapEvents.SwapExecuted, {
        props: {
          assetFrom: `${status.route.srcToken.chain.name}.${status.route.srcToken.symbol}`,
          assetFromAmount: status.route.srcAmount.toFixed(),
          assetTo: `${status.route.destToken.chain.name}.${status.route.destToken.symbol}`,
          assetToAmount: status.route.destAmount.toFixed(),
          rate: status.route.destAmount.ratio(status.route.srcAmount).toFixed(),
          destinationAddress: status.destAddress,
          integration: status.integration,
        },
      });
    }
  }, [swapFinished]);

  return (
    <AnimatePresence mode="wait">
      {status.status === 'waiting_for_src_tx' && (
        <motion.div key="start-swap-status" {...defaultAnimationProps}>
          <StartSwapStatus status={status} />
        </motion.div>
      )}
      {(status.status === 'waiting_for_src_tx_confirmation' ||
        status.status === 'waiting_for_dest_tx' ||
        status.status === 'completed') && (
        <motion.div key="swap-processing-status" {...defaultAnimationProps}>
          <SwapProcessingStatus status={status} depositViewState={depositViewState} />
        </motion.div>
      )}
      {status.status === 'failed' && (
        <motion.div key="error-status" {...defaultAnimationProps}>
          <ErrorStatus status={status} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
