import React from 'react';

export const RefundingFrame = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="300"
    height="182"
    viewBox="0 0 300 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_1681_118035"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="-29"
      width="309"
      height="242"
    >
      <rect y="-28.5" width="309" height="241.5" fill="url(#paint0_linear_1681_118035)" />
    </mask>
    <g mask="url(#mask0_1681_118035)">
      <mask
        id="mask1_1681_118035"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="-3"
        y="-91"
        width="311"
        height="305"
      >
        <rect
          x="-2.10645"
          y="-90.6709"
          width="309.659"
          height="304.445"
          fill="url(#paint1_radial_1681_118035)"
        />
      </mask>
      <g mask="url(#mask1_1681_118035)">
        <circle
          cx="148.552"
          cy="59.9879"
          r="150.137"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9894"
          r="136.583"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9852"
          r="123.029"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9868"
          r="109.475"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9879"
          r="95.9211"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9899"
          r="82.3671"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9889"
          r="67.7704"
          fill="#151515"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9847"
          r="54.2163"
          fill="#191919"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9863"
          r="40.6622"
          fill="url(#paint2_linear_1681_118035)"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          opacity="0.5"
          cx="148.551"
          cy="59.9904"
          r="28.1508"
          fill="url(#paint3_linear_1681_118035)"
          stroke="#424242"
          strokeWidth="1.04262"
        />
        <path
          opacity="0.8"
          d="M171.489 59.9863C171.489 72.6544 161.22 82.924 148.552 82.924C135.884 82.924 125.614 72.6544 125.614 59.9863C125.614 47.3182 135.884 37.0487 148.552 37.0487C161.22 37.0487 171.489 47.3182 171.489 59.9863Z"
          fill="url(#paint4_linear_1681_118035)"
          stroke="#424242"
          strokeWidth="1.04262"
        />
      </g>
    </g>
    <path
      d="M149.709 67.0522C151.202 67.0522 152.662 66.6092 153.904 65.7794C155.146 64.9496 156.114 63.7701 156.686 62.3901C157.257 61.0102 157.407 59.4917 157.116 58.0267C156.824 56.5618 156.105 55.2161 155.049 54.16C153.993 53.1038 152.647 52.3845 151.182 52.0931C149.717 51.8017 148.199 51.9513 146.819 52.5229C145.439 53.0945 144.259 54.0624 143.429 55.3044C142.599 56.5463 142.157 58.0064 142.157 59.5001V62.382M144.271 61.3126L142.459 63.4272L140.042 61.3126"
      stroke="#CDCDCD"
      strokeWidth="1.8125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M152.127 57.0825H148.502C147.835 57.0825 147.294 57.6235 147.294 58.2909C147.294 58.9582 147.835 59.4992 148.502 59.4992H150.919C151.586 59.4992 152.127 60.0402 152.127 60.7075C152.127 61.3748 151.586 61.9159 150.919 61.9159H147.294"
      stroke="#CDCDCD"
      strokeWidth="1.8125"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M149.711 64.3324L149.711 54.6658"
      stroke="#CDCDCD"
      strokeWidth="1.20833"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1681_118035"
        x1="154.5"
        y1="-13.1995"
        x2="154.5"
        y2="168.753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" stopOpacity="0" />
        <stop offset="0.3" stopColor="#D9D9D9" />
        <stop offset="0.49" stopColor="#D9D9D9" />
        <stop offset="0.825897" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1681_118035"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(152.723 61.5518) rotate(90) scale(152.223 154.829)"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_1681_118035"
        x1="148.552"
        y1="18.8027"
        x2="148.552"
        y2="101.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F1F1F" />
        <stop offset="1" stopColor="#272525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1681_118035"
        x1="177.223"
        y1="31.3184"
        x2="119.879"
        y2="88.6625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C2C2C" />
        <stop offset="1" stopColor="#1B1B1B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1681_118035"
        x1="125.093"
        y1="36.5273"
        x2="172.011"
        y2="83.4453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252525" />
        <stop offset="1" stopColor="#393939" stopOpacity="0.67" />
      </linearGradient>
    </defs>
  </svg>
);
