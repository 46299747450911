export const RouteDottedConnect = () => (
  <svg width="56" height="31" viewBox="0 0 56 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="19.5"
      y1="14.6592"
      x2="36.5"
      y2="14.6592"
      stroke="#424242"
      strokeLinecap="round"
      strokeDasharray="2 2"
    />
    <g filter="url(#filter0_d_65_21210)">
      <circle cx="39.5" cy="14.6592" r="3.5" fill="#424242" />
      <circle cx="39.5" cy="14.6592" r="3.75" stroke="#5F5F5F" strokeWidth="0.5" />
    </g>
    <g filter="url(#filter1_d_65_21210)">
      <circle cx="16.5752" cy="14.6592" r="3.5" fill="#424242" />
      <circle cx="16.5752" cy="14.6592" r="3.75" stroke="#5F5F5F" strokeWidth="0.5" />
    </g>
    <defs>
      <filter
        id="filter0_d_65_21210"
        x="25.5"
        y="0.65918"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="5.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_65_21210" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_65_21210"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_65_21210"
        x="0.575195"
        y="0.65918"
        width="30"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="1" />
        <feGaussianBlur stdDeviation="5.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.48 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_65_21210" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_65_21210"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
