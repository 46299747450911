import React from 'react';

export const SuccessFrame = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="300"
    height="182"
    viewBox="0 0 300 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_475_146034" maskUnits="userSpaceOnUse" x="0" y="-29" width="309" height="242">
      <rect y="-28.5" width="309" height="241.5" fill="url(#paint0_linear_475_146034)" />
    </mask>
    <g mask="url(#mask0_475_146034)">
      <mask
        id="mask1_475_146034"
        maskUnits="userSpaceOnUse"
        x="-3"
        y="-91"
        width="311"
        height="305"
      >
        <rect
          x="-2.10645"
          y="-90.6709"
          width="309.659"
          height="304.445"
          fill="url(#paint1_radial_475_146034)"
        />
      </mask>
      <g mask="url(#mask1_475_146034)">
        <circle
          cx="148.552"
          cy="59.9879"
          r="150.137"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9894"
          r="136.583"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9852"
          r="123.029"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9868"
          r="109.475"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9879"
          r="95.9211"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9899"
          r="82.3671"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9889"
          r="67.7704"
          fill="#151515"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9847"
          r="54.2163"
          fill="#191919"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9863"
          r="40.6622"
          fill="url(#paint2_linear_475_146034)"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          opacity="0.5"
          cx="148.551"
          cy="59.9904"
          r="28.1508"
          fill="url(#paint3_linear_475_146034)"
          stroke="#424242"
          strokeWidth="1.04262"
        />
        <path
          opacity="0.8"
          d="M171.489 59.9863C171.489 72.6544 161.22 82.924 148.552 82.924C135.884 82.924 125.614 72.6544 125.614 59.9863C125.614 47.3182 135.884 37.0487 148.552 37.0487C161.22 37.0487 171.489 47.3182 171.489 59.9863Z"
          fill="url(#paint4_linear_475_146034)"
          stroke="#424242"
          strokeWidth="1.04262"
        />
      </g>
    </g>
    <path
      d="M148.82 51.7536C144.376 51.7536 140.774 55.3558 140.774 59.7993C140.774 64.2429 144.376 67.845 148.82 67.845C153.263 67.845 156.866 64.2429 156.866 59.7993C156.866 55.3558 153.263 51.7536 148.82 51.7536ZM139.252 59.7993C139.252 54.5152 143.536 50.2315 148.82 50.2315C154.104 50.2315 158.388 54.5152 158.388 59.7993C158.388 65.0835 154.104 69.3671 148.82 69.3671C143.536 69.3671 139.252 65.0835 139.252 59.7993Z"
      fill="#46DA93"
      stroke="url(#paint5_linear_475_146034)"
      strokeWidth="0.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M149.427 62.3006L149.427 62.3006C149.427 62.3007 149.427 62.3009 149.427 62.301C148.631 63.6873 146.604 63.6039 145.924 62.1566L145.924 62.1565L145.924 62.1564L145.924 62.1563L145.398 61.0336C145.219 60.6531 145.383 60.1999 145.763 60.0214C146.144 59.8429 146.597 60.0066 146.776 60.3871L147.302 61.51L147.302 61.5101C147.302 61.5101 147.302 61.5102 147.302 61.5102C147.459 61.8424 147.924 61.8615 148.107 61.5431L148.107 61.543L148.107 61.5429L148.107 61.5428L150.893 56.6875C151.102 56.3229 151.567 56.1969 151.932 56.4061C152.296 56.6153 152.422 57.0804 152.213 57.445L149.427 62.3006Z"
      fill="#46DA93"
      stroke="url(#paint6_linear_475_146034)"
      strokeWidth="0.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_475_146034"
        x1="154.5"
        y1="-13.1995"
        x2="154.5"
        y2="168.753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" stopOpacity="0" />
        <stop offset="0.3" stopColor="#D9D9D9" />
        <stop offset="0.49" stopColor="#D9D9D9" />
        <stop offset="0.825897" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_475_146034"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(152.723 61.5518) rotate(90) scale(152.223 154.829)"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_475_146034"
        x1="148.552"
        y1="18.8027"
        x2="148.552"
        y2="101.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F1F1F" />
        <stop offset="1" stopColor="#272525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_475_146034"
        x1="177.223"
        y1="31.3184"
        x2="119.878"
        y2="88.6625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C2C2C" />
        <stop offset="1" stopColor="#1B1B1B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_475_146034"
        x1="125.093"
        y1="36.5273"
        x2="172.011"
        y2="83.4453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252525" />
        <stop offset="1" stopColor="#393939" stopOpacity="0.67" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_475_146034"
        x1="148.82"
        y1="50.0815"
        x2="148.82"
        y2="69.5171"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8EF8C5" />
        <stop offset="1" stopColor="#6EC89D" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_475_146034"
        x1="148.82"
        y1="56.155"
        x2="148.82"
        y2="63.4431"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8EF8C5" />
        <stop offset="1" stopColor="#6EC89D" />
      </linearGradient>
    </defs>
  </svg>
);
