import React, { useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { ChevronIcon } from '@/shared/icons/large';
import Button from './flip-ui-kit/Button';

export default function ExpandableContainer({
  label,
  count,
  children,
  className,
  buttonClassName,
  secondaryAction,
}: {
  label: string;
  count: number;
  children: React.ReactNode;
  className?: string;
  buttonClassName?: string;
  secondaryAction?: { label: string; onClick: () => void };
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      data-open={isOpen}
      className={classNames(
        'w-full overflow-hidden rounded-md border border-cf-gray-4 bg-cf-gray-3 font-aeonikMedium text-14 text-cf-light-2 data-open:bg-cf-gray-3-5',
        className,
      )}
    >
      <button
        data-open={isOpen}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          'group flex w-full items-center justify-between p-3 outline-none transition ease-out hover:bg-cf-gray-3-5',
          'font-aeonikMedium data-open:text-cf-white',
          buttonClassName,
        )}
      >
        <span>
          {isOpen ? 'Hide' : 'Show'} {label} ({count})
        </span>
        <div className="flex items-center gap-x-2">
          {secondaryAction && (
            <Button
              type="secondary-standard"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                secondaryAction.onClick();
              }}
            >
              {secondaryAction.label}
            </Button>
          )}
          <span className="flex h-6 w-6 items-center justify-center rounded-md border border-cf-gray-5 bg-cf-gray-4 transition ease-out group-hover:bg-cf-gray-5 group-hover:text-cf-white">
            <ChevronIcon flip={!isOpen} />
          </span>
        </div>
      </button>
      <motion.div
        data-open={isOpen}
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: '0px', opacity: 0 },
        }}
        transition={{ duration: 0.15 }}
        className="flex flex-col overflow-hidden p-3 py-0 text-12 data-open:pb-3"
      >
        {children}
      </motion.div>
    </div>
  );
}
