import type {} from '@redux-devtools/extension'; // required for devtools typing
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

export type DepositViewState =
  | 'awaiting-deposit-tx'
  | 'awaiting-deposit-confirmations'
  | 'swap-boosted'
  | 'boost-skipped'
  | 'transition-finished';
interface ViewTransitionStore {
  depositViewState: DepositViewState;
  setDepositViewState: (depositViewState: DepositViewState) => void;
}

const initialData = {
  depositViewState: 'awaiting-deposit-tx',
} as const;

const useViewTransitionStore = create<ViewTransitionStore>()(
  devtools(
    immer((set) => ({
      ...initialData,
      setDepositViewState: (depositViewState) => set({ depositViewState }),
    })),
  ),
);

export default useViewTransitionStore;
