import classNames from 'classnames';
import { motion } from 'framer-motion';

type StatusConfig = {
  border: string;
  progress: string;
  flagBackground: string;
  flag: string;
};

const colorClasses = {
  active: {
    border: '[background:linear-gradient(90deg,#0C0F0E_0%,#5AF6AB_100%)] border-r-[#5AF6AB]',
    progress:
      '[background:linear-gradient(270deg,#46DA93_0%,rgba(70,218,147,0)_100%)] border-r-[#5AF6AB] border-l-[#0C0F0E]',
    flagBackground: '[background:linear-gradient(270deg,#46DA93_0%,rgba(70,218,147,0)_100%)]',
    flag: '[background:linear-gradient(180deg,#46DA93_0%,#042113_101.35%)]',
  },
  inactive: {
    border: '[background:linear-gradient(90deg,#0C0C0B_0%,#A8E5FF_100%)] border-r-[#A8E5FF]',
    progress:
      '[background:linear-gradient(270deg,#A8E5FF_0%,rgba(247,147,26,0)_100%)] border-r-[#A8E5FF] border-l-[#0C0C0B]',
    flagBackground: '[background:linear-gradient(270deg,#A8E5FF_0%,rgba(247,147,26,0)_100%)]',
    flag: '[background:linear-gradient(180deg,#A8E5FF_0%,rgba(247,147,26,0)_100%)]',
  },
} as const satisfies Record<string, StatusConfig>;

export type ChunkedSwapStatus = keyof typeof colorClasses;

export const ChunkedSwapProgressBar = ({
  status,
  progressPercent,
}: {
  status: ChunkedSwapStatus;
  progressPercent: number;
}) => {
  const classes = colorClasses[status];

  return (
    <div className="relative h-[25px] w-full">
      {/* Dark Background */}
      <div className="absolute left-0 top-0 z-[100] h-[7px] w-full rounded-sm border border-cf-gray-3-5 bg-cf-gray-3-5" />
      {progressPercent !== 0 && (
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: `${progressPercent}%` }}
          transition={{
            type: 'spring',
            mass: 1,
            stiffness: 80,
            damping: 20,
          }}
          className="absolute left-0 top-0 z-[101] flex w-full flex-col"
        >
          {/* Progress bar */}
          <div className="flex h-[7px] flex-col">
            <div className={classNames('h-px w-full border-r', classes.border)} />
            <div className={classNames('h-[5px] w-full border-x', classes.progress)} />
            <div className={classNames('h-px w-full border-r', classes.border)} />
          </div>
          <div className="flex w-full flex-row">
            {/* Flag Background */}
            <div className={classNames('h-[19px] w-full opacity-20', classes.flagBackground)} />
            {/* Flag */}
            <div className={classNames('-ml-px h-[19px] w-px', classes.flag)} />
          </div>
        </motion.div>
      )}
    </div>
  );
};
