import React from 'react';

export const SendingFrame = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="300"
    height="182"
    viewBox="0 0 300 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="mask0_1679_116429" maskUnits="userSpaceOnUse" x="0" y="-29" width="309" height="242">
      <rect y="-28.5" width="309" height="241.5" fill="url(#paint0_linear_1679_116429)" />
    </mask>
    <g mask="url(#mask0_1679_116429)">
      <mask
        id="mask1_1679_116429"
        maskUnits="userSpaceOnUse"
        x="-3"
        y="-91"
        width="311"
        height="305"
      >
        <rect
          x="-2.10645"
          y="-90.6709"
          width="309.659"
          height="304.445"
          fill="url(#paint1_radial_1679_116429)"
        />
      </mask>
      <g mask="url(#mask1_1679_116429)">
        <circle
          cx="148.552"
          cy="59.9879"
          r="150.137"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9894"
          r="136.583"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9852"
          r="123.029"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9868"
          r="109.475"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9879"
          r="95.9211"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9899"
          r="82.3671"
          fill="#161616"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.551"
          cy="59.9889"
          r="67.7704"
          fill="#151515"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9847"
          r="54.2163"
          fill="#191919"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          cx="148.552"
          cy="59.9863"
          r="40.6622"
          fill="url(#paint2_linear_1679_116429)"
          stroke="#252525"
          strokeWidth="1.04262"
        />
        <circle
          opacity="0.5"
          cx="148.551"
          cy="59.9904"
          r="28.1508"
          fill="url(#paint3_linear_1679_116429)"
          stroke="#424242"
          strokeWidth="1.04262"
        />
        <path
          opacity="0.8"
          d="M171.489 59.9863C171.489 72.6544 161.22 82.924 148.552 82.924C135.884 82.924 125.614 72.6544 125.614 59.9863C125.614 47.3182 135.884 37.0487 148.552 37.0487C161.22 37.0487 171.489 47.3182 171.489 59.9863Z"
          fill="url(#paint4_linear_1679_116429)"
          stroke="#424242"
          strokeWidth="1.04262"
        />
      </g>
    </g>
    <g clipPath="url(#clip0_1679_116429)">
      <path
        d="M138.833 57.3891L157.417 51.1946L151.222 69.7782L148.018 60.5932L138.833 57.3891Z"
        stroke="#CDCDCD"
        strokeWidth="1.8125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.232 60.3797L150.582 58.03"
        stroke="#CDCDCD"
        strokeWidth="1.8125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1679_116429"
        x1="154.5"
        y1="-13.1995"
        x2="154.5"
        y2="168.753"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" stopOpacity="0" />
        <stop offset="0.3" stopColor="#D9D9D9" />
        <stop offset="0.49" stopColor="#D9D9D9" />
        <stop offset="0.825897" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1679_116429"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(152.723 61.5518) rotate(90) scale(152.223 154.829)"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_1679_116429"
        x1="148.552"
        y1="18.8027"
        x2="148.552"
        y2="101.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1F1F1F" />
        <stop offset="1" stopColor="#272525" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1679_116429"
        x1="177.223"
        y1="31.3184"
        x2="119.878"
        y2="88.6625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2C2C2C" />
        <stop offset="1" stopColor="#1B1B1B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1679_116429"
        x1="125.093"
        y1="36.5273"
        x2="172.011"
        y2="83.4453"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#252525" />
        <stop offset="1" stopColor="#393939" stopOpacity="0.67" />
      </linearGradient>
      <clipPath id="clip0_1679_116429">
        <rect width="29" height="29" fill="white" transform="translate(134 45)" />
      </clipPath>
    </defs>
  </svg>
);
