import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useWallet } from '@solana/wallet-adapter-react';
import { useWalletModal } from '@solana/wallet-adapter-react-ui';
import { useAccount, useSwitchChain } from 'wagmi';
import { type ChainId, parseEvmChainId, isSolanaChain } from '@/shared/assets/chains';
import { Button, Tooltip } from '@/shared/components';
import { type ButtonProps } from '@/shared/components/flip-ui-kit/Button';

type Props = {
  requireConnection: boolean;
  chainId: ChainId | undefined;
  tooltip?: string;
} & ButtonProps;

const EvmButton = ({ chainId, requireConnection, tooltip, ...props }: Props) => {
  const { isConnected, chain } = useAccount();
  const { switchChain } = useSwitchChain();
  const { openConnectModal } = useConnectModal();

  const srcTokenChainId = parseEvmChainId(chainId) || 1;

  let { children, onClick } = props;
  if (requireConnection && !isConnected) {
    children = 'Connect Wallet';
    onClick = openConnectModal ?? onClick;
  } else if (requireConnection && srcTokenChainId !== chain?.id) {
    children = 'Switch Network';
    onClick = () => switchChain?.({ chainId: srcTokenChainId });
  }

  return (
    <Tooltip content={tooltip} disabled={!tooltip}>
      <Button {...props} onClick={onClick}>
        {children}
      </Button>
    </Tooltip>
  );
};

const SolanaButton = ({ chainId, requireConnection, tooltip, ...props }: Props) => {
  const { signTransaction } = useWallet();
  const { setVisible } = useWalletModal();

  let { children, onClick } = props;
  if (requireConnection && !signTransaction) {
    children = 'Connect Wallet';
    onClick = () => {
      setVisible(true);
    };
  }

  return (
    <Tooltip content={tooltip} disabled={!tooltip}>
      <Button {...props} onClick={onClick}>
        {children}
      </Button>
    </Tooltip>
  );
};

const WalletActionButton = ({ chainId, ...props }: Props): JSX.Element | null => {
  if (isSolanaChain(chainId)) return <SolanaButton chainId={chainId} {...props} />;
  return <EvmButton chainId={chainId} {...props} />;
};

export default WalletActionButton;
